<template>
  <v-row>
    <v-col cols="12" class="sectionBody">
      <!-- essay type -->
      <v-row v-if="questionData.type == 'essay'">
        <v-col cols="8" sm="8">
          <h3
            class="question main-color mb-0"
            v-html="questionData.question"
          ></h3>
        </v-col>
        <v-col cols="4" sm="4">
          <p class="marks main-color">
            {{ $t("Marks") }}: {{ questionData.Marks }}
          </p>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="questionData.student_answer" solo></v-textarea>
          <div class="attachFile mb-4">
            <input
              type="file"
              class="myFileBrowser"
              @change="onFileSelected($event)"
              title="Attach File"
              ref="attachment"
            />
            <!-- ref="attachment" -->
            <v-btn title="Attach File " style="cursor: pointer" icon>
              <v-icon class="main-color">attach_file</v-icon>
            </v-btn>
            <span v-if="!fileName"> {{ $t("Select File") }}</span>
            <v-chip v-if="fileName"> {{ fileName }}</v-chip>
            <p class="error--text" v-if="fileSizeError">{{ fileSizeError }}</p>
          </div>
        </v-col>
      </v-row>

      <!-- mcq type -->
      <v-row v-if="questionData.type == 'tof' || questionData.type == 'mcq'">
        <v-col cols="8" sm="8">
          <h3
            class="question main-color mb-0"
            v-html="questionData.question"
          ></h3>
        </v-col>
        <v-col cols="4" sm="4">
          <p class="marks main-color">
            {{ $t("Marks") }}: {{ questionData.Marks }}
          </p>
        </v-col>
        <v-col cols="12">
          <v-radio-group v-model="questionData.student_answer" row>
            <v-radio
              v-for="(option, Oindex) in questionData.options"
              :key="Oindex"
              label=""
              :value="option.value"
              color="green"
            >
              <template slot="label">
                <p class="mb-0 answer-option" v-html="option.value"></p>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- join -->
      <v-row v-if="questionData.type == 'join'">
        <v-col cols="8" sm="8">
          <p class="question main-color">
            <strong>{{ questionData.question }}</strong>
          </p>
        </v-col>
        <v-col cols="4" sm="4">
          <p class="marks main-color">
            {{ $t("Marks") }}: {{ questionData.Marks }}
          </p>
        </v-col>
        <v-col
          cols="12"
          class="pa-0"
          v-for="(join, Jindex) in questionData.joins"
          :key="Jindex"
        >
          <v-row>
            <v-col cols="4">
              <p
                :hidden="join.join_question == '' || join.join_question == null"
              >
                {{ join.join_question_number }} . {{ join.join_question }}
              </p>
            </v-col>
            <v-col cols="4">
              <div
                :hidden="join.join_question == '' || join.join_question == null"
              >
                <v-select
                  :items="join.all_answers_items"
                  solo
                  v-model="join.student_answer"
                  color="green"
                >
                </v-select>
              </div>
            </v-col>
            <v-col cols="4">
              <p>{{ join.join_answer_number }} . {{ join.join_answer }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn
        class="ui-btn submit"
        @click.prevent="submitAnswer()"
        :disabled="loading"
        :loading="loading"
        >{{ $t("Submit New Answer") }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  name: "questionSubmit",
  data() {
    return {
      loading: false,
      homeWorkId: "",
      questionId: "",
      questionData: {},
      fileName: "",
      fileSizeError: "",
    };
  },
  methods: {
    getQuestionData() {
      axios
        .get(
          this.getApiUrl +
            "/homework/getStudentAnswer/" +
            this.homeWorkId +
            "/" +
            this.questionId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.questionData = response.data.data;
        });
    },
    submitAnswer() {
      if (!this.fileSizeError) {
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
              "/homework/studentAnotherAnswer/" +
              this.homeWorkId +
              "/" +
              this.questionId,
            this.questionData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            console.log(response.data.data);
            if (response.data.status.error == false) {
              this.$router.push("/studentHomeworkdetails/" + this.homeWorkId);
            }
          });
      }
    },
    onFileSelected(event, qindex, question) {
      console.log(qindex);
      this.fileName = event.target.files[0].name;
      let fileSize = event.target.files[0].size / 1024 / 1024;
      if (fileSize <= 50) {
        this.uploadAttachment(event.target.files[0], question);
        this.fileSizeError = "";
      } else {
        this.fileSizeError = "Attachment size shouldn’t exceed 50M";
      }
    },
    uploadAttachment(attach) {
      const fd = new FormData();
      fd.append("file", attach);
      this.loading = true;
      axios
        .post(this.getApiUrl + "/uploadFile", fd, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          this.questionData.file = response.data.data.url;
        });
    },
  },
  mounted() {
    this.homeWorkId = this.$router.currentRoute.params.homeWorkId;
    this.questionId = this.$router.currentRoute.params.questionId;

    this.getQuestionData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";
.sectionBody {
  box-shadow: $box-shadow;
  margin-top: 1rem;
  padding: 1rem;

  .question {
  }
  .marks {
    text-align: right;
  }
}

.mb-0 p {
  margin-bottom: 0 !important;
}
.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
</style>
